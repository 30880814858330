<template>
  <div>
    <topDiv style="width: 100vw;height: 3vh"></topDiv>

    <div style="width: 100vw; height: 70px; background: #c4d8da; position: relative;">
      <span style="color: #6986a6; position: absolute; left: 18%; top: 9px; font-size: 35px;">船舶档案</span>
    </div>

    <div class="main-body">
      <div class="search-box">
        <div class="icon-container">
          <i v-if="!searchContent" class="fas fa-search"></i>
          <i v-if="searchContent" @click="clearContent" class="fas fa-times"></i>
        </div>
        <input
          v-model="searchContent"
          type="text"
          placeholder="请输入港口/船舶名称"
          class="search-input"
          @keyup.enter="searchBoat"
        />
        <el-button type="primary" style="width: 84px;height: 40px;margin-left: 20px;font-size: 14px">船舶档案</el-button>
      </div>
        <el-divider></el-divider>

      <div class="name-frame">
        {{ selectedItem.name }}
      </div>

      <div class="left-right-container">
        <div class="left-half-frame">
          <div class="sub-div">
            <p>MMSI:{{selectedItem.mmsi}}</p><br>
            <p>船长:{{selectedItem.length}}米</p><br>
            <p>载重吨:{{selectedItem.dwt}}</p><br>
            <p>建造年份:{{selectedItem.build}}</p>
          </div>

          <div class="sub-div">
            <p>IMO:{{selectedItem.imo}}</p><br>
            <p>船宽:{{selectedItem.width}}米</p><br>
            <p>船舶类型:{{selectedItem.type}}</p><br>
            <p>船厂:{{selectedItem.build}}</p>
          </div>

          <div class="sub-div">
            <p>呼号:{{selectedItem.callsign}}</p><br>
            <p>设计吃水:{{selectedItem.dwt}}吨</p><br>

          </div>
        </div>

        <div class="right-half-frame">
          <div class="carousel">
            <div class="carousel-inner" :style="carouselStyle">
              <img v-for="(image, index) in images" :key="index" :src="image" class="carousel-item">
            </div>
          </div>
          <!-- 内容 -->
        </div>
      </div>

      <br><br>

      <div class="left-right-container">
        <div class="top-left-half-frame" >
          <p style="font-size: 15px;color: #34a5ef;"><b>船舶资料</b> </p>
        </div>

        <div class="top-right-half-frame" >
          <!-- 内容 -->
          <p style="font-size: 15px;color: #34a5ef;padding-left: 20px"><b> 最新动态</b></p>
        </div>
      </div>

      <div class="left-right-container">
        <div class="left-half-frame2" style="background: #e2f2ff">
          <div class="sub-div2" style="background: #e2f2ff;flex:0.3;">
            <p style="cursor: pointer;" @click="selectOption('basicInfo')"><span v-if="selectedOption === 'basicInfo'">★ </span>基本信息</p><br>
            <p style="cursor: pointer;" @click="selectOption('contact')"><span v-if="selectedOption === 'contact'">★ </span>联系方式</p><br>
            <p style="cursor: pointer;" @click="selectOption('shipSize')"><span v-if="selectedOption === 'shipSize'">★ </span>船舶尺度</p><br>
            <p style="cursor: pointer;" @click="selectOption('MMSIHistory')"><span v-if="selectedOption === 'MMSIHistory'">★ </span>MMSI变更</p><br>
            <p style="cursor: pointer;" @click="selectOption('shipCap')"><span v-if="selectedOption === 'shipCap'">★ </span>船舶仓容</p><br>
            <p style="cursor: pointer;" @click="selectOption('buildInfo')"><span v-if="selectedOption === 'buildInfo'">★ </span>建造信息</p><br>
            <p style="cursor: pointer;" @click="selectOption('other')"><span v-if="selectedOption === 'other'">★ </span>入级记录</p><br>
          </div>

          <div class="sub-div2" v-if="selectedOption ==='basicInfo'" style="background: #f8f8f8">
            <p class="underline1" style="padding-top: 10px">IMO:{{this.selectedItem.imo}}</p><br>
            <p class="underline1">MMSI:{{this.selectedItem.mmsi}}</p><br>
            <p class="underline1">船名:{{this.selectedItem.name}}</p><br>
            <p class="underline1">呼号:{{this.selectedItem.callsign}}</p><br>
            <p class="underline1">类型:{{this.selectedItem.type}}</p><br>
            <p class="underline1">船旗:{{this.selectedItem.flag}}</p><br>
          </div>
          <div class="sub-div2" v-if="selectedOption ==='contact'" style="background: #f8f8f8">
            <p>联系方式</p><br>
          </div>
          <div class="sub-div2" v-if="selectedOption ==='shipSize'" style="background: #f8f8f8">
            <p>船宽:{{this.selectedItem.imo}}</p><br>
            <p>最大船宽:{{this.selectedItem.mmsi}}</p><br>
            <p>型宽:{{this.selectedItem.name}}</p><br>
            <p>设计吃水:{{this.selectedItem.callsign}}</p><br>
            <p>船长:{{this.selectedItem.type}}</p><br>
            <p>两柱间长:{{this.selectedItem.flag}}</p><br>
            <p>总长:{{this.selectedItem.flag}}</p><br>
          </div>
          <div class="sub-div2" v-if="selectedOption ==='MMSIHistory'" style="background: #f8f8f8">
            <p>MMSI变更记录</p><br>
          </div>
          <div class="sub-div2" v-if="selectedOption ==='shipCap'" style="background: #f8f8f8">
            <p>船舶仓容</p><br>
          </div>
          <div class="sub-div2" v-if="selectedOption ==='buildInfo'" style="background: #f8f8f8">
            <p>建造信息</p><br>

          </div>
          <div class="sub-div2" v-if="selectedOption ==='other'" style="background: #f8f8f8">
            <p>入级记录</p><br>
          </div>

        </div>

        <div class="right-half-frame2" style="">
          <!-- 内容 -->
          <img src="@/assets/img/spare.png" style="padding-left: 200px;padding-top: 100px">
        </div>
      </div>

        <br><br>

        <div class="top-left-half-frame" style="text-align: right;" >
          <el-button style="margin-top: 10px" type="primary" @click="setDateRange('day')" class="custom-size-button" plain>近三个月</el-button>
          <el-button type="primary" @click="setDateRange('year1')" class="custom-size-button" plain>近一年</el-button>
          <el-button type="primary" @click="setDateRange('year2')" class="custom-size-button" plain>近两年</el-button>
          <el-button type="primary" @click="setDateRange('year3')" class="custom-size-button" plain>近三年</el-button>
          选择时间
          <el-date-picker
            style="margin-right: 10px"
            v-model="time2"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions">
          </el-date-picker>
        </div>
      <div class="left-right-container">
        <div class="left-half-frame2" style="height: 700px;border-radius: 0 0 10px 10px">
          <div class="left-half-frame2">
            <span>序号</span>
            <span>港口中文</span>
            <span>港口英文</span>
            <span>国家或地区</span>
            <span>时区</span>
            <span>到港时间</span>
            <span>抵港吃水（米）</span>
            <span>靠泊时间</span>
            <span>离泊时间</span>
            <span style="margin-right: 20px">离港吃水(米)</span>
          </div>

        </div>
      </div>


    </div>



  </div>

</template>

<script>
import topDiv from "@/components/topDiv";
// import bus from '@/assets/js/bus';


export default {
  created() {
    if (this.$route.query.selectedItem) {
      this.selectedItem = JSON.parse(this.$route.query.selectedItem);
    }
  },

  name: "moreinfo",
  mounted(){
    this.searchNameChange(this.selectedItem.name);
    this.startAutoScroll();
  },
  computed: {
    carouselStyle() {
      return {
        transform: `translateX(-${this.currentIndex * 100}%)`,
      };
    },
  },

  methods:{

    startAutoScroll() {
      setInterval(() => {
        this.currentIndex = (this.currentIndex + 1) % this.images.length;
      }, 3000); // 3秒
    },
    searchNameChange(name){
      this.searchContent = name;
    },
    selectOption(option) {
      this.selectedOption = option;
    },
    clearContent(){
      this.searchContent="";
    },
    setDateRange(rangeType) {
      const now = new Date();
      let start = new Date();

      switch (rangeType) {
        case 'threeMonth':
          start.setMonth(now.getMonth() - 3); // 设置为三个月前
          break;
        case 'year1':
          start.setFullYear(now.getFullYear() - 1); // 设置为一年前
          break;
        case 'year2':
          start.setFullYear(now.getFullYear() - 2); // 设置为两年前
          break;
        case 'year3':
          start.setFullYear(now.getFullYear() - 3); // 设置为三年前
          break;
      }

      this.time2 = [start, now];
    },

  },
  data(){
    return{
      time2:'',
      currentIndex: 0,
      images: [
        require("@/assets/img/pre/img1.jpg"),
        require("@/assets/img/pre/img2.jpg"),
        require("@/assets/img/pre/img3.jpg"),
        // 在这里添加更多图片路径
      ],
      selectedOption: "basicInfo",
      item:"",
      searchContent:"",
      boatName:"PRELUDE",
      selectedItem:{
        // "id": 1,
        // "imo": 9648714,
        // "name": "\tPRELUDE",
        // "manager": "-",
        // "owner": "-",
        // "length": 489.0,
        // "width": 74.0,
        // "mmsi": 503000101,
        // "flag": "Australia",
        // "type": "Offshore Support Vessel",
        // "callsign": "\tVNKL",
        // "homeport": "-",
        // "build": "2017",
        // "status": "-",
        // "crossTonnage": 499167.0,
        // "dwt": 394330.0,
        // "designSpeed": 0.0,
        // "serviceSpeed": 0.0,
        // "maximumSpeed": 0.0
      },

    }

  },

  components: {
    topDiv,
  },
}
</script>

<style scoped>
.main-body {
  margin-left: 18%;
  margin-right: 18%;
  margin-top: 20px;
}


.search-box {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 8px;
  position: relative; /* 设置相对定位 */
}

.icon-container {
  margin-right: 10px;
  position: absolute; /* 设置绝对定位 */
  top: 50%; /* 向下移动50%的高度 */
  left: 10px; /* 从左边框向右10px */
  transform: translateY(-50%); /* 向上移动自身高度的50% */
  z-index: 10; /* 确保图标在输入框之上 */
}

.icon-container i {
  cursor: pointer;
  font-size: 16px;
  color: #888888;
}
.icon-container:hover i {
  color: #65788d; /* 悬停时的颜色 */
}

.search-input {
  /*flex: 1; !* 充满剩余空间 *!*/
  padding: 15px;
  width: 60%;
  border: none;
  border-radius: 10px 10px 10px 10px; /* 左侧圆角 */
  background-color: rgba(238, 238, 238, 0.89);
  color: #000000;
  outline: none;
  padding-left: 30px; /* 为图标腾出空间 */
}

.name-frame {
  font-size: 30px;
  color: #1e8ae8;
  height: 50px;
  background: #e8f4ff;
  border-radius: 10px 10px 0 0;
  padding-left: 20px; /* 左侧内边距 */
  padding-top: 22px; /* 上部内边距 */
}
.left-right-container {
  display: flex; /* 使用 Flexbox 布局 */
}

.left-half-frame, .right-half-frame {
  font-size: 17px;
  color: #aba5b3;
  height: 250px;
  background: #f8f8f8;
  flex: 1; /* 两个 div 平均分配空间 */
}

.left-half-frame {
  padding-left: 20px;
  padding-top: 20px;
  font-size: 13px;
  color: #aba5b3;
  height: 250px;
  background: #f8f8f8;
  display: flex; /* 应用 Flexbox 布局 */
  justify-content: space-between; /* 子元素平均分布 */
}


/* 如果您确实需要保留固定的宽度和间隔 */
.right-half-frame {
  margin-left: 3%; /* 右侧 div 的左边距 */
  height: 270px;
  display: flex; /* 使用 Flexbox 布局 */
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  /*height: 100%; !* 设置高度，确保有足够的空间进行垂直居中 *!*/
}
.sub-div {
  flex: 1; /* 使所有子 div 平均分配可用空间 */
  /* 可以根据需要添加内边距、外边距或其他样式 */
}
.sub-div2 {
  flex: 1; /* 使所有子 div 平均分配可用空间 */
  margin-left: 0;
  /* 可以根据需要添加内边距、外边距或其他样式 */
}

.top-left-half-frame, .top-right-half-frame {
  font-size: 17px;
  color: #aba5b3;
  height: 50px;
  background: #e1e1e1;
  flex: 1; /* 两个 div 平均分配空间 */
  border-radius: 3px;
}

.top-left-half-frame {
  padding-left: 20px;
  /*padding-top: 20px;*/
  /*font-size: 13px;*/
  justify-content: space-between; /* 子元素平均分布 */
}


/* 如果您确实需要保留固定的宽度和间隔 */
.top-right-half-frame {
  margin-left: 3%; /* 右侧 div 的左边距 */
  height: 50px;
}

.left-half-frame2, .right-half-frame2 {
  font-size: 17px;
  color: #aba5b3;
  height: 400px;
  background: #f8f8f8;
  flex: 1; /* 两个 div 平均分配空间 */
}

.left-half-frame2 {
  padding-left: 20px;
  /*padding-top: 20px;*/
  font-size: 13px;
  color: #aba5b3;
  height: 400px;
  background: #f8f8f8;
  display: flex; /* 应用 Flexbox 布局 */
  justify-content: space-between; /* 子元素平均分布 */
}


/* 如果您确实需要保留固定的宽度和间隔 */
.right-half-frame2 {
  margin-left: 3%; /* 右侧 div 的左边距 */
  /*height: 270px;*/
}


.carousel {
  overflow: hidden;
  width: 100%; /* 轮播容器的宽度 */
  height: 300px; /* 根据需要调整高度 */
}

.carousel-inner {
  display: flex;
  width: 100%;
  transition: transform 0.5s ease-in-out;
}

/*.carousel-item {*/

/*  flex-shrink: 0;*/
/*  width: 100%; !* 每张图片占据容器的100%宽度 *!*/
/*  height: 100%; !* 使图片高度填满轮播容器 *!*/
/*  object-fit: cover; !* 调整图片大小以覆盖整个容器 *!*/
/*}*/
.carousel-item {
  padding-top: 15px;
  flex-shrink: 0;
  width: 100%; /* 每张图片占据容器的100%宽度 */
  height: 268px; /* 使图片高度填满轮播容器 */
  object-fit: cover; /* 调整图片大小以覆盖整个容器 */
}


.custom-size-button {
  width: 80px;
  height: 30px;
  /* 其他自定义样式 */
}

.underline1 {
  width: 85%; /* 调整元素宽度为父元素宽度的50% */
  margin-left: auto;
  margin-right: auto;
  /*padding-left: 10px;*/
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.left-half-frame2 span{
  font-size: 16px;
}

</style>
